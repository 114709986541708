<template>
  <div class="container-fluid">
    <div class="row bg-login-submit">
      <a class="btn login-back btn--dark" :href="backTo">
        <i class="fa fa-chevron-left"></i>
      </a>
      <div class="sup-container" :class="{ active: show.register }">
        <div class="card"></div>
        <div class="card">
          <h1 class="title">Login</h1>
          <div class="input-container">
            <input
              v-model="login.username"
              type="email"
              class="input-standard-grey allow-submit"
              @keyup.enter="signIn"
              id="username"
              placeholder="Email"
            />

            <label for="username">Email</label>
            <span class="fa fa-user input-icon"></span>
            <ErrorPop
              v-show="loginErrorEmail"
              :message="loginErrorEmail"
              @clearError="$store.commit('loginError', null)"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <input
              v-model="login.password"
              :type="login.passwordType"
              class="input-standard-grey allow-submit"
              @keyup.enter="signIn"
              id="password"
              placeholder="Password"
            />
            <label for="password">Password</label>
            <span
              class="fa fa-fw fa-eye password-view-icon toggle-password link"
              @click="changeLoginPasswordType"
            ></span>
            <span class="fa fa-unlock-alt input-icon"></span>
            <ErrorPop
              v-show="loginErrorPassword"
              :message="loginErrorPassword"
              @clearError="$store.commit('loginError', null)"
            ></ErrorPop>
          </div>
          <div class="input-container align-center">
            <button
              @click="signIn"
              class="btn btn-medium btn-arrow btn--primary waves-effect waves-dark"
            >
              <span>Login</span><i class="fa fa-chevron-right"></i>
            </button>
          </div>

          <div class="facebook-divider">
            <span class="facebook-divider-wrapper">Or</span>
          </div>

          <div class="button-container">
            <a
              :href="`https://www.facebook.com/v7.0/dialog/oauth?response_type=code&client_id=${appId.facebook}&redirect_uri=${redirectUri.facebook}&display=popup&scope=email`"
              class="btn btn-small loginwith facebook"
              ><i class="fa-facebook fab"></i
              ><span>Login with <span>Facebook</span></span>
            </a>
            <a
              :href="`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${appId.google}&redirect_uri=${redirectUri.google}&include_granted_scopes=true&scope=email`"
              class="btn btn-small loginwith google"
            >
              <i class="fa-google fab"></i
              ><span>Login with <span>Google</span></span>
            </a>
          </div>
        </div>
        <div class="card alt">
          <div class="toggle"></div>
          <h1 class="title">
            Register
            <span @click="show.register = false" class="close"></span>
          </h1>
          <div class="input-container">
            <input
              v-model="register.email"
              type="email"
              class="input-standard-grey allow-submit"
              @keyup.enter="registerUser"
              id="register-email"
              placeholder="Email"
            />
            <label for="register-email">Email</label>
            <span class="fa fa-envelope input-icon"></span>
            <ErrorPop
              v-show="registerErrorEmail"
              :message="registerErrorEmail"
              @clearError="registerErrors.email = null"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <input
              v-model="register.password1"
              :type="register.passwordType"
              @keyup.enter="registerUser"
              class="input-standard-grey fake-required validation allow-submit"
              id="register-password1"
              placeholder="Password"
            />
            <label for="register-password1">Password</label>
            <span
              class="fa fa-fw fa-eye password-view-icon link"
              @click="changeRegisterPasswordType"
            ></span>
            <span class="fa fa-unlock-alt input-icon"></span>
            <ErrorPop
              v-show="registerErrorPassword1"
              :message="registerErrorPassword1"
              @clearError="registerErrors.password1 = null"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <input
              v-model="register.password2"
              :type="register.passwordType"
              @keyup.enter="registerUser"
              class="input-standard-grey fake-required validation allow-submit"
              id="register-password2"
              placeholder="Password Repeat"
            />
            <label for="register-password2">Password Repeat</label>
            <span class="fa fa-fw fa-eye password-view-icon link"></span>
            <span class="fa fa-unlock-alt input-icon"></span>
            <ErrorPop
              v-show="registerErrorPassword2"
              :message="registerErrorPassword2"
              @clearError="registerErrors.password2 = null"
            ></ErrorPop>
          </div>

          <div class="input-container">
            <meter max="4" id="password-strength-meter"></meter>
          </div>

          <div class="inset-container">
            <div class="row terms-holder">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div id="terms-label" class="pb30 css-checkbox light">
                  <input
                    @click="show.agreementModal = true"
                    v-model="register.terms_and_conditions"
                    type="checkbox"
                    id="register-terms"
                  />
                  <label for="register-terms"
                    >I agree to the
                    <a @click="show.agreementModal = true" href="#"
                      >User Agreement</a
                    ><span></span
                  ></label>
                </div>
                <ErrorPop
                  v-show="registerErrorTerms"
                  :message="registerErrorTerms"
                  @clearError="registerErrors.terms_and_conditions = null"
                ></ErrorPop>
              </div>

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  @click="registerUser"
                  id="create-user"
                  type="submit"
                  :class="{ disabled: disable.register }"
                  class="btn btn-medium btn-arrow btn--primary waves-effect waves-dark"
                >
                  <span>Create User</span>
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sup-footer">
        <p>
          <a href="https://www.mylastwill.co.uk/accounts/password_reset"
            >Forgotten Password</a
          >
        </p>
        <p>
          Not registered?
          <a @click="show.register = true" href="javascript:void(0)"
            >Create Account</a
          >
        </p>
      </div>
    </div>
    <UserAgreementModal
      v-show="show.agreementModal"
      @close="decline"
      @accept="accept"
    />
  </div>
</template>

<script>
import ErrorPop from '../../../common/ui/ErrorPop'
import UserAgreementModal from './UserAgreementModal'
import { http } from '@/services'

export default {
  name: 'LoginRegister',
  components: {
    ErrorPop,
    UserAgreementModal
  },
  created() {
    this.$store.dispatch('logout')
    if (this.$route.path === '/login/facebook') {
      this.sendFacebookCodeToServer(this.$route.query.code)
    } else if (this.$route.path === '/login/google') {
      this.sendGoogleCodeToServer(this.$route.query.code)
    } else if (this.$route.params.access && this.$route.params.refresh) {
      var data = {
        access: this.$route.params.access,
        refresh: this.$route.params.refresh
      }
      this.$store.dispatch('_setTokens', data)
    }
  },
  watch: {
    loginError: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.detail) this.$notification.error(value.detail)
      }
    },
    register: {
      deep: true,
      immediate: true,
      handler(value) {
        this.registerErrors = null
      }
    }
  },
  data() {
    return {
      login: {
        username: null,
        password: null,
        passwordType: 'password'
      },
      register: {
        email: null,
        password1: null,
        password2: null,
        terms_and_conditions: false,
        passwordType: 'password'
      },
      registerErrors: null,
      show: {
        register: false,
        agreementModal: false
      },
      disable: {
        register: false,
        login: false
      },
      appId: {
        facebook: '565436130592959',
        google:
          '116371457156-vh53trpbsvlg0kvh28usv0hljk2vprgr.apps.googleusercontent.com'
      },
      redirectUri: {
        facebook: 'https://wills.mylastwill.co.uk/login/facebook',
        google: 'https://wills.mylastwill.co.uk/login/google'
      }
    }
  },
  computed: {
    backTo() {
      if (process.env.NODE_ENV === 'production') {
        return 'https://www.mylastwill.co.uk'
      } else {
        return 'http://127.0.0.1:8000'
      }
    },
    validateLogin() {
      return !!(this.login.username && this.login.password)
    },
    validateRegister() {
      return !!(
        this.register.email &&
        this.register.password1 &&
        this.register.password2
      )
    },
    loginError() {
      return this.$store.getters.loginError
    },
    loginErrorEmail() {
      if (this.loginError && this.loginError.email)
        return this.loginError.email[0]
      return null
    },
    loginErrorPassword() {
      if (this.loginError && this.loginError.password)
        return this.loginError.password[0]
      return null
    },
    registerErrorTerms() {
      if (this.registerErrors && this.registerErrors.terms_and_conditions)
        return this.registerErrors.terms_and_conditions[0]
      return null
    },
    registerErrorEmail() {
      if (this.registerErrors && this.registerErrors.email)
        return this.registerErrors.email[0]
      return null
    },
    registerErrorPassword1() {
      if (this.registerErrors && this.registerErrors.password1)
        return this.registerErrors.password1[0]
      return null
    },
    registerErrorPassword2() {
      if (this.registerErrors && this.registerErrors.password2)
        return this.registerErrors.password2[0]
      return null
    }
  },
  methods: {
    signIn() {
      this.$store.dispatch('obtainToken', {
        email: this.login.username,
        password: this.login.password
      })
    },
    registerUser() {
      this.disable.register = true
      http
        .post('/wills/api/register', this.register)
        .then((response) => {
          this.login.username = this.register.email
          this.login.password = this.register.password1
          this.signIn()
          this.$notification.success('User Created - Welcome to MyLastWill')
          this.disable.register = false
        })
        .catch((error) => {
          console.log(error)
          this.registerErrors = error.response.data
          this.disable.register = false
        })
    },
    changeLoginPasswordType() {
      if (this.login.passwordType === 'text') {
        this.login.passwordType = 'password'
      } else {
        this.login.passwordType = 'text'
      }
    },
    changeRegisterPasswordType() {
      if (this.register.passwordType === 'text') {
        this.register.passwordType = 'password'
      } else {
        this.register.passwordType = 'text'
      }
    },
    sendFacebookCodeToServer(code) {
      http
        .post('/api/login/social/jwt-pair/facebook/', {
          provider: 'facebook',
          code: code,
          redirect_uri: this.redirectUri.facebook
        })
        .then((response) => {
          var data = {
            access: response.data.token,
            refresh: response.data.refresh
          }
          this.$store.dispatch('_setTokens', data)
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    },
    sendGoogleCodeToServer(code) {
      http
        .post('/api/login/social/jwt-pair/', {
          provider: 'google-oauth2',
          code: code,
          redirect_uri: this.redirectUri.google
        })
        .then((response) => {
          var data = {
            access: response.data.token,
            refresh: response.data.refresh
          }
          this.$store.dispatch('_setTokens', data)
        })
        .catch((error) => {
          console.log(error.response.data)
        })
    },
    accept() {
      this.register.terms_and_conditions = true
      this.show.agreementModal = false
    },
    decline() {
      this.show.agreementModal = false
      this.register.terms_and_conditions = false
    }
  }
}
</script>

<style scoped>
.link {
  cursor: pointer;
  text-decoration: none;
}

#terms-label label {
  text-decoration: none;
  font-size: 1.2em;
  color: white;
}
#terms-label label a {
  color: #e56781;
}
</style>
